import React from "react";
import { withGetScreen } from 'react-getscreen';
import "./sass/navbar.scss";
import { NavLink } from "react-router-dom";

class NavBar extends React.Component{

    render(){
        return(
            <div className="navbar">
                    <a className="navlink navlink:hover first-child" href="/">
                        Home
                    </a>
                    <a className="navlink" href="/Skills">
                        Skills
                    </a>
                    <a className="navlink" href="/Projects">
                        Projects
                    </a>
                    <a className="navlink" href="/About">
                        About
                    </a>
                    <a className="navlink" href="/ContactMe">
                        Contact Me
                    </a>
            </div>
        )
    }
}

export default withGetScreen(NavBar)