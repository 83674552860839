import React from 'react';
import './sass/bouncing-text.scss';

const BouncingText = ({ text }) => {
    const letters = text.split('').map((letter, idx) => (
      <span key={idx} style={{ animationDelay: `${0.1 * idx}s` }}>{letter}</span>
    ));
  
    return <div className="bouncing-text">{letters}</div>;
  };
  
  export default BouncingText;