import React from "react";
import NavBar from "../components/NavBar";
import "../components/sass/background.scss"
import "../components/sass/home.scss"

class Home extends React.Component {
    render() {
        return (
            <div className="background-gradient">
                    <NavBar></NavBar>
                    <div className= "section">
                        <div className="page-title-container">                            
                            <div className= "page-title">
                                Home
                            </div>
                            <div className= "page-subtitle">
                                Elegant Design.
                            </div>
                            <p className= "page-paragraph">
                            Welcome to my portfolio.
                            I am Callum Peel, a Software Developer dedicated to elegance and functionality in design. 
                            My designs showcase a steadfast attention to detail and a dedication to crafting intuitive user experiences.
                            </p>
                        </div>
                        <a>
                            <img className="homePic" src='images/qw.png' alt="Me"></img>
                        </a>
                    </div>
            </div>
        );
    }
}

export default Home;

