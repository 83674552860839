import React from "react";
import NavBar from "../components/NavBar";
import "../components/sass/background.scss"

class Skills extends React.Component {
    render() {
        return (
            <div className="background-gradient">
                <content className= "polygonBackground">
                    <NavBar />
                    <div className= "section">
                    </div>
                </content>
            </div>
        );
    }
}

export default Skills;

