import React from "react";
import NavBar from "../components/NavBar";
import "../components/sass/background.scss"
import "../components/sass/youtubeVideo.scss"
import VideoEmbed from "../components/YoutubeVideo"
import "../components/sass/projects.scss"
import BouncingText from "../components/BouncingText"


class Projects extends React.Component {
    render() {
        return (
            <div className="background-gradient">
                    <NavBar></NavBar>
                <div className="section-one">
                    <BouncingText text="Projects" />
                    <div className="video-player-settings">
                        <VideoEmbed embedId="KlGMVLSMbGg" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Projects;

