// Filename - App.js

import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";

// import pages
import Home from "./pages/Home";
import About from "./pages/About";
import ContactMe from "./pages/ContactMe";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";


function App() {
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<Home/>} />
				<Route path="/About" element={<About />} />
				<Route path="/ContactMe" element={<ContactMe />} />
				<Route path="/Projects" element={<Projects />} />
				<Route path="/Skills" element={<Skills />} />
			</Routes>
		</Router>
	);
}

export default App;
